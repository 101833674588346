import React from 'react';
import phone from '../assets/Scene-5.png';
import gplay from '../assets/Google Play.png';
import astore from '../assets/App Store.png'
import footerbg from '../assets/footerbgftransperant.png';
import env from '../assets/env.svg';
import ph from '../assets/Phone.svg';
import whatsap from '../assets/Whatsapp.svg';
import fb from '../assets/fb.svg';
import insta from '../assets/instagram.svg'
import git from '../assets/github.svg';
import twitter from '../assets/twitter.svg'
import buildings from '../assets/buildings.svg'

function Footer() {
  return (
    <div className='font-poppins'>
      <div className='lg:mb-20 mb-8 px-0'>
        <div className='bg-gradient-to-r from-[#02afa3] to-[#1883b4] 2xl:mt-40 xl:mt-40 lg:mt-40 overflow-x-clip py-10 mx-auto w-[90%]'>
          <div className='flex lg:flex-nowrap flex-wrap items-center 2xl:w-[80%] xl:w-[80%] mx-auto px-5'>
            <div className='w-full lg:w-1/2 2xl:w-2/6 h-auto mx-auto'>
              <h1 className='2xl:text-4xl text-xl text-white font-bold text-center lg:text-left'>Plan your trip from your pocket.</h1> <br/>
              <p className='text-white font-normal text-center lg:text-left'>
                Yes! you heard it right, now plan your vacation from your pocket, we Tourise mooze presenting you the feature rich mobile app for traveling.
              </p>
              <br/>
            </div>

            <img src={phone} alt='mob' className='lg:w-[60%] w-[100%] mx-auto xl:-mt-36 lg:-mt-28 lg:-mr-48 xl:-mr-72 -mr-16 sm:-mr-20 md:-mr-40' />

            <div className='w-full lg:w-1/2 2xl:w-2/6 h-auto mx-auto'>
              <p className='text-white font-normal text-center lg:text-left'>Download our feature rich mobile applications via App store & Play store for completely free...</p>
              <p className='font-bold text-white py-5 text-center lg:text-left'>Plan your trip now !</p>
              <div className="grid grid-cols-2 gap-4 lg:w-full mx-auto w-72">
                <img src={gplay} alt="Google Play Store button" className="h-auto mx-auto" />
                <img src={astore} alt="App Store button" className="h-auto mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main footer */}
      <div className='relative bg-gradient-to-r from-[#20478d] to-[#068bca] w-full lg:flex hidden lg:px-48 pt-20 pb-10' 
      // style={{ backgroundImage: `url(${footerbg})`,backgroundSize: 'cover' }}
      >
         {/* Content inside the main footer, if any */}
         <div
            className="grid grid-cols-1 gap-8 pt-8 sm:grid-cols-2 lg:grid-cols-4 min-h-[300px] "
            >
            <div>
                <p className="font-medium text-gray-100">Touris Mooze</p>

                <ul className="mt-6 space-y-4 text-sm">
                <li>
                    <a href="#" className="text-gray-300 transition hover:opacity-75"> About </a>
                </li>

                <li>
                    <a href="#" className="text-gray-300 transition hover:opacity-75"> Contact us </a>
                </li>

                </ul>
            </div>

            <div>
                <p className="font-medium text-gray-100">Policy</p>

                <ul className="mt-6 space-y-4 text-sm">
                <li>
                    <a href="#" className="text-gray-300 transition hover:opacity-75"> Terms and Conditions </a>
                </li>

                <li>
                    <a href="#" className="text-gray-300 transition hover:opacity-75"> Privacy Policy </a>
                </li>

                <li>
                    <a href="#" className="text-gray-300 transition hover:opacity-75"> FAQ </a>
                </li>
                </ul>
            </div>

            <div>
                <p className="font-medium text-gray-100">Connect</p>

                <ul className="mt-6 space-y-4 text-sm">
                <li className='flex'>
                    {/* <a href="#" className="text-gray-300 transition hover:opacity-75"> Log In </a> */}
                    <img src={env} className='text-white mr-2' alt='abc' />
                    <p className='text-gray-300 transition hover:opacity-75'>plans@tourismooze.com</p>
                </li>

                <li className='flex'>
                  <img src={env} className='text-white mr-2' alt='abc' />
                  <p className='text-gray-300 transition hover:opacity-75'>+91 9874554455</p>
                </li>

                <li className='flex'>
                  <img src={env} className='text-white mr-2' alt='abc' />
                  <p className='text-gray-300 transition hover:opacity-75'>+91 9874885522</p>
                </li>
                </ul>
            </div>

            <div className='px-10'>
              <p className='text-white font-semibold text-xl'>Follow</p>
              <div className='flex space-x-4 py-4'>
                <img src={fb} className='text-white text-2xl' alt='abc' />
                <img src={insta} className='text-white text-2xl' alt='abc' />
                <img src={git} className='text-white text-2xl' alt='abc' />
                <img src={twitter} className='text-white text-2xl' alt='abc' />
              </div>
            </div>

            </div>
          <img className='absolute bottom-0 right-0 h-[400px]' src={footerbg}></img>
      </div>
       {/* End main footer */} 
    
{/* mobile footer */}

<div className='block lg:hidden w-full bg-gradient-to-b from-[#20478d] to-[#068bca] px-2 pt-5 rounded-t-xl'>

<div className='grid grid-cols-2 p-2 gap-3 justify-between'>
          <div>
            <p className='text-white font-semibold'>Touris mooze</p>
            <p className='text-white text-sm  '>About</p>
            <p className='text-white text-sm'>Contact us</p>
          </div>


          <div className=''>
            <p className='text-white font-semibold'>Follow</p>
            <div className='grid grid-cols-4 mt-2 gap-2'>
          <img src={fb} className='text-white text-2xl' alt='abc' />
          <img src={insta} className='text-white text-2xl' alt='abc' />
          <img src={git} className='text-white text-2xl' alt='abc' />
          <img src={twitter} className='text-white text-2xl' alt='abc' />
        </div>
          
          </div>
         



          <div className=''>
          <p className='text-white font-semibold'>Connect</p>
        <div className='flex items-center py-1'>
          <img src={env} className='text-white mr-2' alt='abc' />
          <p className='text-white font-normal text-sm'>plans@tourismooze.com</p>
        </div>
        <div className='flex items-center py-1'>
        <img src={ph} className='text-white mr-2' alt='abc' />
          <p className='text-white font-normal text-sm'>+91 9874554455</p>
        </div>
        <div className='flex items-center py-1'>
        <img src={whatsap} className='text-white mr-2' alt='abc' />
          <p className='text-white font-normal text-sm'>+91 9874885522</p>
        </div>
          </div>


          <div className='ml-10'>
            <p className='text-white font-semibold'>Policy</p>
            <p className='text-white font-normal text-sm '>Terms and Conditions</p>
            <p className='text-white font-normal text-sm '>Privacy policy</p>
            <p className='text-white font-normal text-sm'>FAQ</p>
          </div>
          
   


</div>
<img src={buildings} alt='abc' />

</div>

{/* end mobile footer */}

     
      
    </div>
  );
}

export default Footer;