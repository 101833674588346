import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import bgimage from '../assets/BG.svg';
import axios from 'axios';
import { AuthContext } from './AuthContext';

function MyBooking() {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [bookingData, setBookingData] = useState([]);
  const [filter, setFilter] = useState('confirmed');

  useEffect(() => {
    const fetchMyOrders = async () => {
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.post(
          'https://admin.tourismooze.com/api/public/my_orders',
          {},
          { headers }
        );

        console.log(response, 'full response');
        const MybookingData = response.data.data; 
        console.log(MybookingData, 'myorders');

        if (Array.isArray(MybookingData)) {
          setBookingData(MybookingData);
        } else {
          console.error('Expected an array but got:', MybookingData);
        }
      } catch (error) {
        console.error('Error fetching my orders:', error.response || error);
      }
    };

    fetchMyOrders();
  }, [token]);

  const filteredBookings = bookingData.filter((plan) => {
    if (filter === 'confirmed') return plan.states === 'captured';
    if (filter === 'inprogress') return plan.states === 'pending';
    if (filter === 'cancelled') return plan.states === 'failed';
    return false;
  });

  return (
    <div
      className="w-full h-screen overflow-scroll relative items-center justify-center"
      style={{ backgroundImage: `url(${bgimage})`, backgroundSize: 'cover' }}
    >
      <div className='w-full md:w-[86%] mx-auto'>
        <button
          onClick={() => navigate(-1)}
          className="flex items-center my-2 focus:outline-none bg-[#1E75BB] rounded-md text-white font-poppins px-2 py-1"
        >
          <IoIosArrowBack className="mr-2 text-white" size={18} />Go Back
        </button>
        <p className='text-center font-poppins font-[900] text-3xl py-4'>My Booking</p>

        <div className='flex w-full md:w-[80%] mx-auto justify-between my-4'>
          <div onClick={() => setFilter('confirmed')} className={`w-full cursor-pointer p-3 rounded-l-full text-center font-poppins border-r-[#11A59B] border-r[1px] ${filter === 'confirmed' ? 'bg-white text-[#11A59B]' : 'bg-[#009187] text-white'}`}>
            confirmed
          </div>
          <div onClick={() => setFilter('inprogress')} className={`w-full cursor-pointer p-3 text-center font-poppins border-r-[#11A59B] border-r[1px] ${filter === 'inprogress' ? 'bg-white text-[#11A59B]' : 'bg-[#009187] text-white'}`}>
            In progress
          </div>
          <div onClick={() => setFilter('cancelled')} className={`w-full cursor-pointer p-3 rounded-r-full text-center font-poppins border-r-[#11A59B] border-r[1px] ${filter === 'cancelled' ? 'bg-white text-[#11A59B]' : 'bg-[#009187] text-white'}`}>
            Cancelled
          </div>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-between gap-2'>
  {filteredBookings.length > 0 ? (
    filteredBookings.map((popular, index) => (
      <div key={index} className="bg-white p-5 rounded-md shadow-md w-full border-[1px] border-[#0BD1C4]">
        <p className=" font-poppins text-[#006464] text-[20px] font-semibold py-2 px-2">{popular.package_name}</p>
        <p className='text-[#1E75BB] font-poppins px-2 py-2'>{popular.booking_date}</p>
        <div className='bg-[#C6FFE4] p-2 py-4'>
        <p className='text-[#202020] font-bold pt-2'>{popular.states}</p>
        <p className='font-poppins text-[#587893] pb-2 font-light'>BOOING ID : {popular.order_id}</p>
        <p className='text-[#587893] font-poppins'>{popular.package_description}</p>
        </div>
        <button 
  onClick={() => navigate('/package-details', { state: { popular, isFromBooking: true } })} 
  className='font-poppins bg-[#1E75BB] flex p-5 rounded-full text-white mx-auto text-center mt-8 mb-2'
>
  VIEW DETAILS
</button>

      </div>
    ))
  ) : (
    <p className="text-center">No bookings available.</p>
  )}
</div>

      </div>
    </div>
  );
}

export default MyBooking;
