// src/data/currencies.js
import inflag from '../assets/inflag.png';
import usflag from '../assets/usflag.png';
import uaeflag from '../assets/uaeflag.png';
import euflag from '../assets/euflag.png';

export const currencies = [
    { value: 'USD', label: 'USD', flag: usflag },
    { value: 'EUR', label: 'EUR', flag: euflag },
    { value: 'INR', label: 'IND', flag: inflag },
    { value: 'AUD', label: 'AUD', flag: uaeflag },
    // Add more currencies as needed
];
