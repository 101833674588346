import React, { useEffect, useState } from 'react';
import BG2 from '../assets/BG2.svg';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
import axios from 'axios';
import vector from '../assets/Vector.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles for the date picker
import { FaCalendarAlt } from 'react-icons/fa'; // Optional: Importing a calendar icon for better UI
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';




function ConfirmPlan({onLoginClick,onLogoutClick}) {
  const { token } = useContext(AuthContext);
  const [destinations, setDestinations] = useState([]);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSugession, setSelectedSugession] = useState('');
  const [counter, setCounter] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedadultcount, setSelectedAdultCount] = useState(1);
  const [childrencount, setChildrenCount] = useState(0);
  const [roomcount, setRoomCount] = useState(1);
  const [countryId,setCountryId] = useState('');
  const [loader,setLoader] = useState(false)
  const baseUrl = 'https://admin.tourismooze.com/storage/';
  const MySwal = withReactContent(Swal);

  const navigate = useNavigate()

  useEffect(() => {
    fetchSelectedDestinations();
  }, []);

  // Function for fetching selected destinations
  const fetchSelectedDestinations = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.post(`https://admin.tourismooze.com/api/public/selected`, {}, { headers });

      // Access the correct path of selectedDestinations in the response
      const destinationData = response.data.data?.selectedDestinations || []; // Fallback to empty array if undefined
      setDestinations(destinationData); // Set the correct data
      console.log(destinationData, '*********');
      
    } catch (error) {
      console.error("Error fetching selected destinations:", error);
    }
  };





  const handleRemove = async (index,id)=>{
    try {
        const response = await axios.post(
          `https://admin.tourismooze.com/api/public/selected/remove/${id}`,
          {
            payload:{
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data.status)
        if(response.data.status == true){
            fetchSelectedDestinations()
        }
      } catch (error) {
        console.error('Error toggling favorite:', error);
      }
}


const handleInputChange = async event => {
  const value = event.target.value;
  setQuery(value);
  if (value) {
    fetchSuggestions(value);
  } else {
    setSuggestions([]);
  }
};



const fetchSuggestions = async (value) => {
  try {
    const response = await axios.post(
      'https://admin.tourismooze.com/api/public/countries',
      { search: value},
      { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
    );
    const data = response.data;
    const suggestions = data.map(item => item);

    const countryid = data.map(item => item.id);
    setSuggestions(suggestions);
    setCountryId(countryid);
  } catch (error) {
    console.error('Error fetching suggestions:', error);
  }
};

const handleSuggestionClick = (id,countryname) => {
  setQuery(countryname);
  setSuggestions([]);
  setSelectedSugession(id);
  setCountryId()
};



const handleNUmberChange = (e) => {
  const newValue = e.target.value;
  setCounter(newValue);  // Update the counter value
  console.log('Counter value:', newValue);  // Log to console
};




const handleDateChange = (date) => {
  const formattedDate = date.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD' and exclude time
  setSelectedDate(formattedDate); // Update the selected date in the state
  console.log('Selected date:', formattedDate); // Log the formatted date
};



  const increaseAdult = () => {
    setSelectedAdultCount(prev => {
      const newCount = prev + 1;
     
      

      return newCount;
    });
  };
  
  const decreaseAdult = () => {
    setSelectedAdultCount(prev => {
      const newCount = Math.max(1, prev - 1);
      
      return newCount;
    });
  };



  const increaseChildrenCount = () => {
    setChildrenCount(prev => {
      const newCount = prev + 1;
      return newCount;
    });
  };
  
  const decreaseChildrenCount = () => {
    setChildrenCount(prev => {
      const newCount = Math.max(0, prev - 1);
      
      return newCount;
    });
  };


  const increaseRoomCount = () => {
    setRoomCount(prev => {
      const newCount = prev + 1; 
      return newCount;
    });
  };
  
  const decreaseRoomCount = () => {
    setRoomCount(prev => {
      const newCount = Math.max(1, prev - 1);
      return newCount;
    });
  };






// submitting plan
const submitPlan = async () => {
  console.log(selectedSugession,'````````````')
  setLoader(true);
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  
    const payload = {
      adults: selectedadultcount,
      children: childrencount,
      rooms: roomcount,
      duration: counter,
      booking_date: selectedDate, // Ensure this is in 'YYYY-MM-DD' format
      country: selectedSugession,
    };
  
    try {
      // Send payload directly without wrapping it inside another object
      const response = await axios.post(
        'https://admin.tourismooze.com/api/public/enquiry',
        payload, // Pass payload directly
        { headers } // Pass headers as the third argument
      );
      console.log(response.data.status); // Log the response data
      if(response.data.status == true){
        setChildrenCount('');
        setCounter('');
        setCountryId('')
        setDestinations('')
        setQuery('')
        setRoomCount('')
        setLoader(false);
        navigate('/plan-success');
        
      } 
      if (response.data.errors) {
        for (const error of response.data.errors.country) {
          MySwal.fire({
            title: <p className='text-[18px] text-red-500 font-poppins'>{error}</p>,
            footer: 'Tourismooze',
          });
            setLoader(false)
        }
    } else {
        console.log('No errors found.');
    }
    
      
    } catch (error) {
    }

};

// end submitting plan



















  // JSX Return
  return (
    <div className='h-auto' style={{ backgroundImage: `url(${BG2})`, backgroundSize: 'cover' }}>
      <div className='w-full h-24 bg-[#AEF1EC] flex items-center rounded-b-2xl'>
        <div className='md:w-[80%] w-full mx-auto justify-center'>
          <p className='font-poppins text-2xl text-[#0FA499] font-bold'>Confirm Your Plan</p>
        </div>
      </div>

      <div className='h-24'></div>

      <div className='md:w-[85%] w-full mx-auto'>
        <div className='flex flex-wrap xl:flex-nowrap gap-5 justify-between'>
          <div className='w-full'>
          <label className='font-poppins text-white font-semibold text-lg'>Departure date</label> <br />
      <div className='relative backdrop:blur-lg flex items-center my-4 bg-white/10 h-12 rounded-full w-full px-5'>
        {/* DatePicker input */}
        <DatePicker
        required
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy" // Customize the date format
          placeholderText='Select a date'
          minDate={new Date()} // Disable past dates
          className='bg-transparent outline-none cursor-pointer text-[#FFFFFF] placeholder:text-[#FFFFFF] font-poppins w-full' // Style it to blend with the design
        />
        {/* Optional: Add a calendar icon for better UX */}
        <FaCalendarAlt className="absolute right-4 text-[#FFFFFF] cursor-pointer" />
      </div>
            <label className='font-poppins text-white font-semibold text-lg'>Departure Country</label> <br />
            <div className='backdrop:blur-lg flex items-center my-4 bg-white/10 h-12 rounded-full w-full'>
              <input required type="text" value={query} onChange={handleInputChange} className='bg-transparent outline-none cursor-pointer text-[#FFFFFF] placeholder:text-[#FFFFFF] font-poppins ml-5' placeholder='Search' />
            </div>
            {suggestions.length > 0 && (
             <div className='h-56 overflow-y-auto bg-[#AEF1EC] border-none rounded-sm relative'>
             <ul className="border border-gray-300 mt-2 rounded shadow-lg">
               {suggestions.map((suggestion, index) => (
                 <li
                   key={index}
                   onClick={() => handleSuggestionClick(suggestion.id,suggestion.countryname)}
                   className="p-2 cursor-pointer hover:bg-gray-200"
                 >
                   {suggestion.countryname}
                   
                 </li>
                 
               ))}
             </ul>
           
             {/* Custom Scrollbar Styling */}
             <style jsx>{`
               /* Hide scrollbar for Chrome, Safari and Opera */
               .h-56::-webkit-scrollbar {
                 display: none;
               }
           
               /* Hide scrollbar for IE, Edge and Firefox */
               .h-56 {
                 -ms-overflow-style: none;  /* IE and Edge */
                 scrollbar-width: none;  /* Firefox */
               }
             `}</style>
           </div>
           
  )}
            <label className='font-poppins text-white font-semibold text-lg'>Duration of your trip</label> <br />
            <div className='backdrop:blur-lg flex items-center my-4 bg-white/10 h-12 rounded-full w-full'>
            <input required min={1}
        type='number'
        value={counter}  // Bind the input to the counter state
        onChange={handleNUmberChange}  // Event handler for input change
        className='bg-transparent outline-none cursor-pointer w-full text-[#FFFFFF] placeholder:text-[#FFFFFF] font-poppins ml-5 mr-5'
        placeholder='Select duration'
      />
            </div>
          </div>

          {/* Selected Destinations */}
          <div className='w-full'>
            <p className='font-poppins text-white font-semibold text-lg'>Selected Destinations</p>
            <div>
              {destinations.length > 0 ? (
                destinations.map((destination, index) => (
                  <div key={index} className='bg-white rounded-md pb-6 p-3 m-2 shadow'>
                    <div className='flex flex-wrap md:flex-nowrap gap-4 relative'>
                    <img 
                      src={baseUrl+destination.destination_image} 
                      alt={destination.destination_name} 
                      className='w-36 object-cover mt-2 rounded-md'
                    />
                    <div>
                    <p className='font-poppins font-semibold text-2xl text-[#006464]'>{destination.destination_name}</p>
                    <p className='text-md text-gray-600 font-poppins pb-4'>{destination.destination_heading}</p>
                   
                    <div className='flex justify-end gap-4 items-center absolute right-1 w-full'>
                        <button onClick={() => handleRemove(index,destination.id)}>REMOVE</button>
                        <img src={vector} alt='minus' />
                    </div>

                    </div>
                    
                    </div>
                  </div>
                ))
              ) : (
                <p onClick={()=>navigate('/explore-destination')}>No destinations selected.</p>
              )}
            </div>
          </div>

          <div className='w-full'>

                <div className='bg-white rounded-lg p-6 relative xl:-mt-36'>
                  <p className='font-poppins text-xl text-[#1E75BB] font-semibold mb-2 p-4'>Choose No of Travellers</p>



<div className='bg-[#1E75BB] p-4 rounded-t-2xl'>
<div className='flex flex-wrap justify-between items-center mb-12'>
    <div className='font-poppins text-[white] text-[14px]'>Adults</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseAdult} className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>-</button>
      </div>
      <div className='text-center font-poppins text-white'>{selectedadultcount}</div>
      <div>
        <button onClick={increaseAdult}  className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>+</button>
      </div>
    </div>
  </div>

  <div className='flex flex-wrap justify-between items-center mb-12'>
    <div className='font-poppins text-[white] text-[14px]'>Children's (0 to 14years)</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseChildrenCount} className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>-</button>
      </div>
      <div className='text-center font-poppins text-white'>{childrencount}</div>
      <div>
        <button onClick={increaseChildrenCount}  className='border-[1px] text-[14px] px-2 border-white text-white font-poppins rounded-xl'>+</button>
      </div>
    </div>
  </div>

  <div className='flex flex-wrap justify-between items-center mb-12'>
    <div className='font-poppins text-[white] text-[14px]'>Rooms</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseRoomCount} className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>-</button>
      </div>
      <div className='text-center font-poppins text-white'>{roomcount}</div>
      <div>
        <button onClick={increaseRoomCount}  className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>+</button>
      </div>
    </div>
  </div>
</div>


<div className='h-12'></div>
<button 
  onClick={submitPlan} 
  className='bg-[#1E75BB] rounded-full px-20 py-3 text-white text-center mx-auto justify-center font-poppins flex items-center'
>
  <p>{loader == true ? 'loading...':'REQUEST BOOKING'}</p>
</button>

  
    </div>

          </div>
        </div>

      </div>


<div className='h-36'></div>
<Footer />
  </div>      
  );
}

export default ConfirmPlan;
