import React, { useState, useEffect } from "react";
import CurrencySelector from "./CurrencySelector";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { currencies } from "../data/currencies";
import menui from "../assets/menui.png";
import Banner from "./Banner";
import ImageGrid from "./ImageGrid";
import BannerWithCards from "./BannerWithCards";
import Popup from "./Popup";
import ProfileDrawer from "./ProfileDrawer";
import bgimage from '../assets/BG.svg';


function Home({ onLoginClick }) {
  const defaultCurrency = currencies.find(
    (currency) => currency.value === "INR"
  );
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [profiledrawer, setProfileDrawer] = useState(false);

  const handleCurrencyChange = (newCurrency) => {
    setSelectedCurrency(newCurrency);
  };

  useEffect(() => {
    console.log("Selected currency:", selectedCurrency);
  }, [selectedCurrency]); // Log the selected currency whenever it changes

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div style={{ backgroundImage: `url(${bgimage})`, backgroundSize: 'cover' }} className="">
      <div className="flex flex-col lg:flex-row justify-between items-center py-4 px-6 xl:w-[80%] w-[100%] mx-auto">
        {profiledrawer == true ? (
          <div
            onClick={() => setProfileDrawer(false)}
            className="fixed inset-0 bg-black bg-opacity-50 z-30"
          ></div>
        ) : (
          ""
        )}

        {profiledrawer == true ? (
          <ProfileDrawer onClose={() => setProfileDrawer(false)} />
        ) : (
          ""
        )}

        {/* Drawer section */}
        {isDrawerOpen && (
          <div className="fixed top-0 left-0 h-24 w-full bg-blue-400 z-50 flex flex-col justify-center items-center">
            <div className="p-6">
              <Link to="/destination-details" className="mx-4 lg:mx-0 text-md">
                Destination detail page
              </Link>
              <Link to="/package-details" className="mx-4 lg:mx-0 text-md">
                Explore Destinations
              </Link>
              <Link to="/pick-a-package" className="mx-4 lg:mx-0 text-md">
                Explore Packages
              </Link>
              <Link to="/destinations" className="mx-4 lg:mx-0 text-md">
                Destinations
              </Link>
              <Link to="/profile-drawer" className="mx-4 lg:mx-0 text-md">
                view profile drawer
              </Link>
              <Link to="/plan-your-trip" className="mx-4 lg:mx-0 text-md">
                plan your trip
              </Link>
              <span className="text-white" onClick={toggleDrawer}>
                Close
              </span>
            </div>
          </div>
        )}
        {/* end Drawer section */}

        {/* First section (Burger menu) */}
      </div>
      <Banner />

      {/* explore section */}
      <div className="container mx-auto flex flex-col gap-6 my-12">
        <h1 className="text-2xl font-poppins text-primary font-semibold tracking-wide text-center lg:text-left">
          Explore
        </h1>
        <div>
          <ImageGrid />
        </div>
      </div>

      <BannerWithCards />
      {/* end explore section */}

      {isPopupVisible && <Popup onClose={handleClosePopup} />}
    </div>
  );
}

export default Home;
