// src/components/CurrencySelector.js
import React from 'react';
import Select, { components } from 'react-select';
import { currencies } from '../data/currencies';

// Custom single value component to include flag and label
const SingleValue = ({ data }) => (
  <div className="flex items-center space-x-2">
    <img src={data.flag} alt={`${data.label} flag`} className="w-6 h-4" />
    <span>{data.label}</span>
  </div>
);

// Custom option component to include flag and label
const Option = (props) => (
  <components.Option {...props}>
    <div className="flex items-center space-x-2">
      <img src={props.data.flag} alt={`${props.data.label} flag`} className="w-3 h-2" />
      <span>{props.data.label}</span>
    </div>
  </components.Option>
);

const CurrencySelector = ({ selectedCurrency, setSelectedCurrency }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '0.5rem',
      borderColor: '#d1d5db', // Tailwind's gray-300
      borderRadius: '0.375rem', // Tailwind's rounded-md
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#9ca3af', // Tailwind's gray-400
      },
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '0.5rem',
      backgroundColor: state.isSelected ? '#60a5fa' : state.isFocused ? '#dbeafe' : '#ffffff',
      color: state.isSelected ? '#ffffff' : '#000000',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const options = currencies.map((currency) => ({
    value: currency.value,
    label: currency.label,
    flag: currency.flag,
  }));

  return (
    <Select
      value={selectedCurrency}
      onChange={setSelectedCurrency}
      options={options}
      styles={customStyles}
      components={{ SingleValue, Option }} // Use custom SingleValue and Option components
    />
  );
};

export default CurrencySelector;
