import React, { useState } from 'react'
import bgimage from '../assets/BG.svg';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import hand from '../assets/hand.svg'

function SuccessPlan() {
  const [success,setSuccess]=useState(true);
  const navigate = useNavigate()
  return (
    <div className='h-screen flex items-center justify-center' style={{ backgroundImage: `url(${bgimage})`, backgroundSize: 'cover' }}>
        {success ?
        <div className="flex items-center justify-center w-full h-screen">
          <div className="bg-white p-8 rounded-xl shadow-lg w-full mx-2 md:w-[60%] xl:w-[50%]">
          {/* <button className='bg-slate-400 p-1 rounded-full float-right' onClick={()=>setSuccess(false)}>
                <FaTimes size={16} color="white" />
                </button> */}
            <img src={hand} className='mx-auto' />
            <p className='text-center font-bold text-black font-poppins text-[20px] md:text-[26px] m-3'>Your plan has been submitted</p>
            <p className='text-center text-[#587893] font-poppins p-2 text-[20px]'>Our tour managers will check and connect with you soon, and confirm your package and let you know the next step</p>
            <div className='flex flex-wrap md:flex-nowrap gap-2 justify-center items-center font-poppins m-5'>
              <button onClick={() => navigate('/my-trip-plans')} className='border-[1.5px] border-[#1E75BB] text-[#1E75BB] font-semibold py-2 px-5 rounded-full'>My trip plans</button>

              <button onClick={()=> navigate('/')} className='text-white bg-[#11A59B] font-semibold py-2 px-8 rounded-full'>Go HOME</button>
            </div>
          </div></div>:'' }
    </div>
  )
}

export default SuccessPlan