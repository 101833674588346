import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";
import "../Nav.css";

import { ArrowLeftCircleIcon, ArrowRightCircle } from "lucide-react";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import React, { useEffect, useRef, useState } from "react";
import bgimage from '../assets/BG.svg';

import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import pkg from "../assets/packag-bg.svg";
import pkgone from "../assets/destination.svg";
import { useNavigate } from "react-router-dom";

const baseUrl = "https://admin.tourismooze.com/storage/";

const BannerWithCards = () => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const [popularPackagedata, setPopularPackageData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const popular = popularPackagedata;

  // Fetch trending destinations
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("https://admin.tourismooze.com/api/public/home");
        if (response.data.data && response.data.data.trending_destinations) {
          const trendingDestinations = response.data.data.trending_destinations;
          setData(trendingDestinations);
        } else {
          throw new Error("Trending destinations data not found");
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch popular packages
  useEffect(() => {
    const fetchPopularData = async () => {
      try {
        const response = await axios.post("https://admin.tourismooze.com/api/public/popular_packages", {
          headers: {
            Authorization: "Bearer your_token_here",
            "Content-Type": "application/json",
          },
        });
        const popularpackages = response.data.data.packages;
        setPopularPackageData(popularpackages);
      } catch (error) {
        console.error("Error fetching popular packages", error);
      }
    };
    fetchPopularData();
  }, []);

  // Display loading or error messages
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <ArrowLeftCircleIcon className="absolute" />,
    nextArrow: <ArrowRightCircle className="absolute" />,
  };

  return (
    <div className="relative h-screen/2 bg-transparent">
      {/* Gradient Banner */}
      <div className="absolute inset-0 bg-gradient-to-t from-[#11a59b] to-[#1F75B9] h-1/2" />

      <div className="flex flex-wrap justify-between relative w-[95%] lg:w-[80%] mx-auto text-left items-center pt-10 px-2 py-5">
        <div className="items-center w-full lg:w-[50%]">
          <h1 className="text-4xl font-bold text-white mb-2 font-poppins text-center lg:text-left">
            Trending Destinations
          </h1>
          <p className="text-lg text-white mb-3 font-poppins text-center lg:text-left">
            Explore trending tourist destinations
          </p>
        </div>

        <button className="text-white text-center lg:text-right w-full lg:w-[50%] flex items-center justify-center lg:justify-end">
          <Link to={"/trending-destinations"}> View All</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 ml-2 lg:ml-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        </button>
      </div>

      {/* Mobile Slider for trending destinations */}
      <div className="block px-2 container mx-auto overflow-x-clip w-[95%] lg:w-[80%]">
        <Slider {...settings} ref={sliderRef}>
          {data.map((destination, index) => (
            <div
              key={index}
              className="relative mb-4 w-full rounded-lg overflow-hidden shadow-lg cursor-pointer"
              onClick={() => navigate("/destination-details", { state: { destination } })}
            >
              <img
                src={baseUrl + destination.destination_image}
                alt={destination.destination_name}
                className="w-full lg:h-96 h-72 object-cover"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-transperant to-transperant bg-opacity-50 p-4 pt-16">
                <p className="text-lg font-bold text-white">{destination.destination_name}</p>
                <h2 className="text-sm text-white">{destination.destination_heading}</h2>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Popular Packages Section */}
      <div className="flex flex-wrap justify-between relative w-[95%] lg:w-[80%] mx-auto text-left items-center pt-10 px-2 py-5">
        <div className="items-center w-full lg:w-[50%]">
          <h1 className="text-3xl font-bold text-secondary mb-2 font-poppins text-center lg:text-left">
            Popular Packages
          </h1>
          <p className="text-md text-tertiary font-thin mb-3 font-poppins text-center lg:text-left">
            Explore places with our budget-friendly packages.
          </p>
        </div>

        <button className="text-black text-center lg:text-right w-full lg:w-[50%] flex items-center justify-center lg:justify-end">
          <Link to={"/popular-packages"}>View All</Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 ml-2 lg:ml-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        </button>
      </div>

      <div className="p-4 w-[95%] lg:w-[80%] mx-auto 2xl:px-8 lg:px-4 md:px-2">
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
          {/* section design  */}
          {popularPackagedata.map((popular, index) => (
            <div
              key={index}
              className="flex flex-col p-2 my-auto rounded-3xl shadow-md border border-[#c6e4e4] bg-[#f5f5f5] h-full"
            >
              <div className="flex gap-4 flex-wrap xl:flex-nowrap items-center flex-grow">
                <div className="w-full">
                  <img
                    className="object-cover w-full rounded-xl"
                    src={baseUrl + popular.package_image}
                    alt="tourizmoose"
                  />
                </div>
                <div className="p-0 grid w-full h-full">
                  <div>
                    <h1 className="text-[#2f7a75] mb-1 xl:text-2xl lg:text-2xl md:text-xl sm:text-2xl text-2xl font-bold md:w-72 w-full text-left">
                      {popular.name}
                    </h1>

                    <span className="text-[#1F75B9] text-md my-3 text-left">{popular.short_description}</span>

                    <ul className="flex flex-wrap items-center w-82 justify-left text-[#587893] py-3 text-md list-none">
                      {popular.additional_infos.map((info, i) => (
                        <li
                          className="relative before:absolute before:left-0 before:-top-2 px-5 before:content-['\2022'] before:text-4xl before:text-[#38bdf8]"
                          key={i}
                        >
                          {info}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="place-self-end  grid grid-cols-2 w-full items-center justify-between gap-5">
                    <span className="text-left text-[#2f7a75]">
                      <b className="text-[#2f7a75] text-xl md:text-[18px] font-poppins">₹ {popular.price_per_person}</b>
                      /person
                    </span>
                    <button
                      onClick={() => navigate("/package-details", { state: { popular } })}
                      className="bg-[#11A59B] px-3 py-2 text-white rounded-br-2xl"
                    >
                      KNOW MORE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* end section design */}
        </div>
        <p className="font-poppins text-[#11A59B] font-semibold text-xl my-12">What's more ?</p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">

<div className="relative cursor-pointer" onClick={()=>navigate('popular-packages')}>
  <img src={pkg} alt="Image 1" className="w-full" />
  <p className="absolute bottom-24 left-5 text-white px-2 font-poppins text-2xl font-bold py-1">View all<br/> packages</p>
</div>

<div className="relative cursor-pointer" onClick={()=>navigate('/destinations')}>
  <img src={pkgone} alt="Image 2" className="w-full" />
  <p className="absolute bottom-24 left-5 text-white px-2 font-poppins text-2xl font-bold py-1">View all <br/> destinations</p>
</div>

</div>

      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default BannerWithCards;
