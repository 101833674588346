import React, { useState, useEffect } from "react";
import bgimage from "../assets/BG.svg";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "./AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import tick from "../assets/tick.svg";
import hand from "../assets/hand.svg";

function Payment() {
  // State to control the popup visibility
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [plandata,setPlanData]= useState([])
  const MySwal = withReactContent(Swal);
  // Get data passed via location state
  const location = useLocation();
  const { payload, Details,isViaPlan,popular } = location.state || {}; // Access the passed data
  const { token } = useContext(AuthContext);
  const { currency } = useContext(AuthContext);
  const [success, setSuccess] = useState(false);
  const { username } = useContext(AuthContext);
  const { userEmail } = useContext(AuthContext);



  console.log(isViaPlan,'plandataaaa');
  console.log(popular,'0000000000000')
  console.log("Payload:", payload);
  console.log("Booking Details:", Details);
  const navigate = useNavigate();




  useEffect(() => {
    const fetchData = async () => {
      if (isViaPlan) {
        try {
          const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          };

          const response = await axios.post(
            `https://admin.tourismooze.com/api/public/enquiry_package/${popular.id}`,
            null,
            { headers }
          );

          const data = response.data.data.package_detail; // Access the data from the response
          setPlanData(data);
          
        } catch (error) {
          console.error("Error fetching package data:", error);
        }
      }
    };

    fetchData();
  }, [isViaPlan, popular, token]); // Add dependencies to the useEffect


















  // Set the booking data from Details on page load
  useEffect(() => {
    if (Details) {
      setBookingData(Details); // Set the booking data
    }
    setIsPopupOpen(true); // Trigger popup on page load
  }, [Details]);

  // payment gateway function
  // Function to handle payment from the frontend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Configure Razorpay payment options
      const options = {
        key: "rzp_test_VJUwvh3mekn7r0", // Razorpay API key
        amount: `${isViaPlan ? plandata.grand_total : bookingData.grand_total}` * 100, // Payment amount (in paise)
        // amount:1*100,  // Payment amount (in paise)

        currency: currency,
        name: "Tourismooze",
        description: "For testing purpose",
        handler: function (response) {
          // Handle successful payment (payment ID and order ID)
          console.log("Payment ID:", response.razorpay_payment_id);
          console.log("Order ID:", response.orderId);
          console.log(response, ".............");
          // Optional: Call backend to validate the payment
          if (response.razorpay_payment_id) {
            verifyPayment(response);
          }
        },
        theme: {
          color: "#07a291db",
        },
        prefill: {
          name: username,
          email: userEmail,
        },
        modal: {
          ondismiss: function () {
            console.log("Payment modal closed");
          },
        },
      };

      // Open Razorpay modal
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  // Optional function to verify payment by calling backend
  const verifyPayment = async (paymentResponse) => {
    const headers = {
      Authorization: `Bearer ${token}`, // Pass your token here
      "Content-Type": "application/json",
    };

    // Define the payload with necessary data
    const payload = {
      payment_id: paymentResponse.razorpay_payment_id, // Ensure you're passing the correct field
      currency: currency, // Ensure the correct currency
      amount: bookingData.grand_total, // Pass the total amount
    };

    try {
      // Pass the payload as the second parameter and headers as the third
      const verifyResponse = await axios.post(
        "https://admin.tourismooze.com/api/public/make_payment",
        payload, // Pass the payload here
        { headers: headers } // Add headers as the third argument
      );

      console.log("Payment verified:", verifyResponse.data);
      if (verifyResponse.status == 200) {
        // MySwal.fire({
        //   title: <p className='text-sm font-poppins'>{`${verifyResponse.data.message}`}</p>,
        //   footer: 'Tourismooze',
        // })
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
    }
  };

  // end payment gateway function

  return (
    <div
      className="h-screen w-full relative flex items-center justify-center"
      style={{ backgroundImage: `url(${bgimage})`, backgroundSize: "cover" }}
    >
      {/* Background content with blur when popup is open */}
      <div
        className={`absolute inset-0 transition-all duration-500 ${
          isPopupOpen ? "bg-[#5f5f5f34] bg-opacity-30" : ""
        }`}
        style={{ backgroundImage: `url(${bgimage})`, backgroundSize: "cover" }}
      >
        <div className="w-[78%] mx-auto p-5">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center my-2 focus:outline-none bg-tertiary rounded-md text-white px-2 py-2"
          >
            <IoIosArrowBack className="mr-1 text-white" size={18} />
            Go Back
          </button>
        </div>
      </div>

      {/* Popup */}
      {isPopupOpen && !success && (
        <div className="relative z-10 flex items-center justify-center w-full h-screen">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full mx-2 md:w-[76%]">
            <button
              className="bg-slate-400 p-1 rounded-full float-right"
              onClick={() => setIsPopupOpen(false)}
            >
              <FaTimes size={16} color="white" />
            </button>
            {bookingData ? (
              <div>
                <p className="md:text-[30px] text-[20px] font-bold mb-4">
                  Hi, {bookingData.username}
                </p>
                <p className="text-tertiary font-poppins text-sm md:text-[18px]">
                  Please confirm your selected package and pricing <br /> and
                  proceed to payment.
                </p>
                <br />
                <br />

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 justify-between w-full">
                  {/* first grid */}
                  <div>
                    <p className="md:text-[20px] text-[14px] font-bold mb-4 font-poppins">
                      Selected Package
                    </p>
                    <div className="bg-[#E4FFFF] px-6 py-5 rounded-xl">
                      <p className="text-[#006464] font-bold text-2xl">
                        {bookingData.package_name} {isViaPlan && plandata.name}
                      </p>
                      {!isViaPlan && <li className="relative list-none pl-6 before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-[#11A59B] before:content-[''] before:w-3 before:h-3 before:rounded-full text-[#587893] font-poppins my-2 text-[12px] md:text-[14px]">
                        {bookingData.adults} Adults {bookingData.children} child
                      </li>}

                      <li className="relative list-none pl-6 before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-[#11A59B] before:content-[''] before:w-3 before:h-3 before:rounded-full text-[#587893] font-poppins my-2 text-[12px] md:text-[14px]">
                        {bookingData.number_of_nights} {isViaPlan && plandata.number_of_nights} nights{" "}
                        {bookingData.number_of_days} {isViaPlan && plandata.number_of_days} days
                      </li>
                      <p className="text-right text-[#1E75BB] font-poppins text-[14px] my-2 md:text-[18px]">
                        Total : ₹ {bookingData.total} {isViaPlan && plandata.total}
                      </p>
                      <p className="text-right text-[#1E75BB] font-poppins text-[14px] my-2 md:text-[18px]">
                        GST(18%) : ₹ {bookingData.gst} {isViaPlan && plandata.gst}
                      </p>
                      <p className="text-right text-[#006464] font-poppins text-[14px] my-2 md:text-[18px]">
                        Grand Total :{" "}
                        <b className="text-[25px] md:text-[30px] font-poppins font-bold">
                          {" "}
                          ₹ {bookingData.grand_total} {isViaPlan && plandata.grand_total}
                        </b>
                      </p>
                    </div>
                  </div>
                  {/* end first grid */}

                  {/* seconf grid */}
                  <div>
                    <p className="md:text-[20px] text-[14px] font-bold mb-4 font-poppins">
                      Selected Payment method
                    </p>
                    <div className="bg-[#E4FFFF] p-2 py-6 rounded-xl">
                      <p className="font-bold text-black font-poppins p-2">
                        Razoorpay
                      </p>
                    </div>
                    <div className="bg-[#AEF1EC] mt-6 py-8 px-5 rounded-xl">
                      <p className="text-[#006464] font-bold text-2xl p-2">
                        {bookingData.package_name}
                      </p>
                      <p className="text-left text-[#006464] font-poppins text-[16px] my-2 md:text-[20px] pb-6">
                        total :{" "}
                        <b className="text-[25px] md:text-[50px] font-poppins font-extrabold">
                          {" "}
                          ₹ {bookingData.grand_total} {isViaPlan && plandata.grand_total}
                        </b>
                      </p>
                    </div>

                    <div
                      onClick={handleSubmit}
                      className="bg-[#11A59B] cursor-pointer flex gap-2 justify-center text-center mx-auto w-[80%] lg:w-[50%] -mt-10 text-white font-poppins py-4 rounded-full"
                    >
                      <img src={tick} />
                      <p>Pay Now</p>
                    </div>
                  </div>
                  {/* end second grid */}
                </div>
              </div>
            ) : (
              <p>No booking data available.</p>
            )}
          </div>
        </div>
      )}

      {success ? (
        <div className="relative z-10 flex items-center justify-center w-full h-screen">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full mx-2 md:w-[60%] xl:w-[50%]">
            <button
              className="bg-slate-400 p-1 rounded-full float-right"
              onClick={() => setSuccess(false)}
            >
              <FaTimes size={16} color="white" />
            </button>
            <img src={hand} className="mx-auto" />
            <p className="text-center font-bold text-black font-poppins text-[20px] md:text-[26px] m-3">
              We received your payment{" "}
            </p>
            <p className="text-center text-[#587893] font-poppins p-2 text-[20px]">
              Our tour managers will check and connect with you soon, and
              confirm your package and let you know the next step
            </p>
            <div className="flex flex-wrap md:flex-nowrap gap-2 justify-center items-center font-poppins m-5">
              <button
                onClick={() => navigate("/my-booking")}
                className="border-[1.5px] border-[#1E75BB] text-[#1E75BB] font-semibold py-2 px-5 rounded-full"
              >
                My packages
              </button>

              <button
                onClick={() => navigate("/")}
                className="text-white bg-[#11A59B] font-semibold py-2 px-8 rounded-full"
              >
                Go HOME
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Payment;
