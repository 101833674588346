import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaHeart } from 'react-icons/fa';
import { AiOutlineHeart } from 'react-icons/ai';
import Footer from './Footer';
import { AuthContext } from './AuthContext';
import bg from '../assets/BG.svg';
import { useNavigate } from 'react-router-dom';
import vector from '../assets/Vector.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import locationpin from '../assets/locationpin.svg'
import mappin from '../assets/mappin.svg'
import listicon from '../assets/listicon.svg'
import dwarrow from '../assets/dwarrow.png';
import uparrow from '../assets/uparrow.png';
import greentick from '../assets/greentick.svg'







function ExploreDestination() {

  const MySwal = withReactContent(Swal);
  const baseUrl = 'https://admin.tourismooze.com/storage/';
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [exploredata, setExploreData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [likedDestinations, setLikedDestinations] = useState([]);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState('');
  const [tourismType, setTourismType] = useState('');
  const [selectedSugession, setSelectedSugession] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [favoritedata, setFavouritesData] = useState([]);
  const [fetchFavDone, setFetchFavDone] = useState(false);
  const [fetchDestDone, setFetchDestDone] = useState(false);
  const [heart,setHeart]= useState([]);
  const [districts,setDistrics] = useState([]);
  const [mapview,setMapView] = useState(false)
  const countRef = useRef(1);






 // State to control dropdown visibility
 const [isOpen, setIsOpen] = useState(false);



 // Function to toggle dropdown
 const toggleDropdown = () => {
   setIsOpen(!isOpen);
 };


 const [selectedDistricts, setSelectedDistricts] = useState([]);

 // Toggle selection of a district
 const toggleSelection = (district) => {
   if (selectedDistricts.includes(district)) {
     // Remove if already selected
     setSelectedDistricts(selectedDistricts.filter(d => d !== district));
   } else {
     // Add if not selected
     setSelectedDistricts([...selectedDistricts, district]);

   }
 };

 // Log selected districts to the console
 const handleSelection = () => {
  console.log("Selected Districts:", selectedDistricts);

 };













  const increment = () => {
    countRef.current += 1;
    FetchDestination();
  };

  const decrement = () => {
    if (countRef.current > 1) {
      countRef.current -= 1;
      FetchDestination();
    }
  };

  useEffect(() => {
    FetchDestination();
  }, []);

  useEffect(() => {
    if (token == null && !fetchDestDone) {
      FetchDestination();
      setFetchDestDone(true)
    }
  }, [token, fetchDestDone]);

  useEffect(() => {
    fetchFilterData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    // ... other useEffect logic
  
    if (token !== null) {
      fetchfavDestinations();
    } else {
      setLikedDestinations([]);
      setFavouritesData([]);
    }
  }, [token]);




  useEffect(() => {
    if (token !== null && !fetchFavDone) {
      fetchfavDestinations();
      setFetchFavDone(true);
    }
  }, [token, fetchFavDone]);

  useEffect(() => {
    // Update likedDestinations based on data and favoritedata
    if (token !== null && data.length > 0) {
      const likedIds = favoritedata.map(fav => fav.id);
      const likedDestinations = data.filter(dest => likedIds.includes(dest.id));
      setLikedDestinations(likedDestinations);
    } else {
      // When token is null or data is empty, clear likedDestinations
      setLikedDestinations([]);
    }
  }, [token, data, favoritedata]);

  const fetchfavDestinations = async () => {
    try {
      const response = await axios.post(
        'https://admin.tourismooze.com/api/public/selected',
        {
          payload: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const favoritedata = response.data.data.selectedDestinations;
      setFavouritesData(favoritedata);
    console.log(favoritedata,'ooooo')
      

      // Filter liked destinations from all destinations
      const likedIds = favoritedata.map(fav => fav.id);
      const likedDestinations = data.filter(dest => likedIds.includes(dest.id));
      setLikedDestinations(likedDestinations);
    } catch (error) {
      console.error('Error fetching favorite destinations:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post('https://admin.tourismooze.com/api/public/home');
      if (response.data.data && response.data.data.categories) {
        setExploreData(response.data.data.categories.slice(0, 6));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchFilterData = async () => {
    try {
      const response = await axios.post('https://admin.tourismooze.com/api/public/popular_packages');

      const priceLimits = response.data.data.pricelimit.map(priceObj => Object.entries(priceObj)[0]);
    } catch (error) {
      console.error('Error fetching filter data:', error);
    }
  };

  const handleInputChange = async event => {
    const value = event.target.value;
    setQuery(value);
    if (value) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };


  
  const fetchSuggestions = async value => {
    try {
      const response = await axios.post(
        'https://admin.tourismooze.com/api/public/plan_a_trip',
        { query: { your_field: value } },
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      );
      const data = response.data.data.destinations.data;
      const suggestions = data.map(item => item.destination_name);
      setSuggestions(suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSuggestionClick = suggestion => {
    setQuery(suggestion);
    setSuggestions([]);
    setSelectedSugession(suggestion);
  };


  const HandleFilter = async () => {
    FetchDestination();
  };

  const HandleClear = async  () => {
   
    try {

      await FetchDestination();
    } catch (error) {
      console.error('Error clearing filters and fetching data:', error);
    }
   
  };


  const cleanPrice = (price) => {
    // Implement the cleaning logic here. For example:
    // Remove any non-numeric characters (excluding the decimal point)
    return price.replace(/[^0-9.]/g, '');
  };



  const FetchDestination = async () => {
    const cleanedPrice = cleanPrice(selectedPrice);
    const districts = selectedDistricts.map((district) => district.city);

    try {
      const payload = {
        selectedDay: selectedDay,
        tourismType: tourismType,
        search: selectedSugession,
        category: tourismType,
        District: districts,   // Corrected districts mapping
        selectedPrice: cleanedPrice,
      };

      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.post(
        `https://admin.tourismooze.com/api/public/destination?page=${countRef.current}`,
        payload,
        { headers }
      );
      setQuery('');
      setSuggestions([]);
      setTourismType('');
      setSelectedDay('');
      setSelectedSugession('');
      setSelectedPrice('');
      setSelectedDistricts([])
      if (response.data.data && response.data.data.destinations.data) {
        setData(response.data.data.destinations.data);
        const likedIds = favoritedata.map(fav => fav.id);
        const likedDestinations = response.data.data.destinations.data.filter(dest => likedIds.includes(dest.id));
        setLikedDestinations(likedDestinations);
       
      } else {
        throw new Error('Trending destinations data not found');
      }
    } catch (error) {
      console.error('Error fetching destinations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (index,id, isLiked) => {
    if (token === null) {
      MySwal.fire({
        title: <p className='text-md text-red-500 font-poppins'>Please Login</p>,
        footer: 'Tourismooze',
      });
      return;
    }

    try {
      const response = await axios.post(
        `https://admin.tourismooze.com/api/public/toggle/selected/${id}`,
        {
          payload:{
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.status !== undefined) {
        // Update liked status in UI
        if (response.data.status === false && isLiked) {
          setLikedDestinations(prevLiked => prevLiked.filter(dest => dest.id !== id));
        }
         else {
          const newLikedDestination = data.find(dest => dest.id === id);
          if (newLikedDestination) {
            
            setLikedDestinations(prevLiked => [...prevLiked, newLikedDestination]);
            
          }
        }
      }
      fetchfavDestinations()
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };


  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };





const handleRemove = async (index,id)=>{
    try {
        const response = await axios.post(
          `https://admin.tourismooze.com/api/public/selected/remove/${id}`,
          {
            payload:{
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data.status)
        if(response.data.status == true){
            fetchfavDestinations()
        }
      } catch (error) {
        console.error('Error toggling favorite:', error);
      }
}
 

useEffect(()=>{
    fetchDistricts()
},[])



const fetchDistricts = async ()=>{
    try{
        const response = await axios.post(
            'https://admin.tourismooze.com/api/public/plan_a_trip',
            {
                payload:{
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                }
              },
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
          );
          const data = response.data.data.districts;
          setDistrics(data);
          console.log(districts,'kkkkkkkkkkkkk')
    } catch {

    }
}













  
  return (

    
    <div style={{ backgroundImage: `url(${bg})` }} className='bg-cover bg-center bg-no-repeat'>
      {/* First Section */}
      <div className='w-full rounded-b-[30px] bg-[#AEF1EC] h-auto'>
        <div className='grid lg:grid-cols-2 grid-cols-1 justify-between items-center lg:py-4 py-3 px-6 xl:w-[80%] w-[100%] mx-auto gap-2'>
          <div>
            <h1 className='font-poppins text-[#006464] text-xl lg:text-2xl font-bold lg:text-left text-center'>Destinations  to explore</h1>
            <p className='text-[#1E75BB] font-poppins text-sm md:text-md lg:text-left text-center'>Explore places based on budget, tourism type and exploration time</p>
          </div>
          <div className='justify-center'>

            <div className='flex m-[2px] items-center cursor-pointer bg-[#1E75BB] justify-center lg:float-end float-none text-center py-3 md:p-1 md:rounded-full rounded-xl gap-2 md:gap-2 mx-auto' onClick={() => setSideNavOpen(true)}>
              <div className='bg-white p-2 rounded-full'>
                <img src={locationpin} className='w-10' />
              </div>
              <div className='text-white font-poppins text-[15px] text-left pr-4 relative'>
                <p className='text-right absolute right-5 -mt-4 text-[12px] bg-red-600 rounded-full px-2 py-[1px]'>{favoritedata.length}</p>
                <p className='m-[1px]'>DESTINATIONS</p>
                </div>   
            </div>
            


      <div className='flex m-[2px] items-center bg-[white] border-[1px] border-[#0BD1C4] justify-center lg:float-end float-none text-center py-3 md:p-1 md:rounded-full rounded-xl gap-2 md:gap-2 mx-auto'>

              <div className='bg-white p-2 rounded-full'>
                { !mapview ? <img src={mappin} className='w-10' />: <img src={listicon} className='w-10' />}
              </div>
              <div className='text-white font-poppins text-[15px] text-left pr-4 relative'>
                {!mapview ?<p className='m-[1px] text-black cursor-pointer font-poppins' onClick={()=>{setMapView(true)}}>MAP VIEW</p>:<p className='m-[1px] cursor-pointer text-black font-poppins' onClick={()=>{setMapView(false)}}>LIST VIEW</p>}
                </div>

            </div>

          </div>
        </div>
      </div>
      {/* End First Section */}

      {/* Body Main Grid Section */}
      <div className='md:flex grid grid-cols-1 xl:w-[80%] w-[100%] mx-auto my-12 gap-4'>
      {/* Grid-1 */}
      <div className='sm:w-[50%] lg:w-[30%] h-auto md:block xl:p-10 lg:p-6 p-3 rounded-xl border-[#c6e4e4] border-[1px] bg-white'>

<div>
<h1 className='text-[#11A59B] font-poppins text-md font-semibold mb-5'>Search</h1>
  <input
    type="text"
    value={query}
    onChange={handleInputChange}
    className="w-full p-2 border border-gray-300 rounded-3xl"
    placeholder="Search places..."
  />
  {suggestions.length > 0 && (
    <ul className="border border-gray-300 mt-2 rounded shadow-lg">
      {suggestions.map((suggestion, index) => (
        <li
          key={index}
          onClick={() => handleSuggestionClick(suggestion)}
          className="p-2 cursor-pointer hover:bg-gray-200"
        >
          {suggestion}
        </li>
      ))}
    </ul>
  )}
</div>
{/* Tourism Type Section */}
<div>
<h1 className='text-[#11A59B] font-poppins text-md font-semibold my-5'>Select tourism type</h1>
{/* <div className='gap-2 mx-auto grid grid-cols-3 overflow-scroll overflow-x-clip'>
{exploredata.map((categories, index) => {
const isSelected = tourismType === categories.id;
return (
  <div key={index} onClick={()=>setTourismType(categories.id)} className={`cursor-pointer p-2 rounded-md transition duration-300 ease-in-out 
    ${isSelected ? 'bg-[#11A59B] text-white' : 'bg-white text-black'}
    hover:shadow-lg hover:scale-105`}>
      <img className='object-cover rounded-full w-auto mx-auto' src={baseUrl + categories.image_path} alt={categories.name} />
      <p className='text-center font-poppins font-semibold text-[10px] lg:text-[12px] p-1'>{categories.name}</p>
    </div>
);
})}
</div> */}

<div className='w-full'>
<Slider {...settings} ref={sliderRef}>
{exploredata.slice(0,3).map((categories, index) => {
const isSelected = tourismType === categories.id;
return (
  <div key={index} onClick={()=>setTourismType(categories.id)} className={`cursor-pointer p-1 rounded-md transition duration-300 ease-in-out 
    ${isSelected ? 'bg-[#11A59B] text-white' : 'bg-white text-black'}
    hover:shadow-lg hover:scale-105`}>
      <img className='object-cover rounded-full w-auto mx-auto' src={baseUrl + categories.image_path} alt={categories.name} />
      <p className='text-center font-poppins font-semibold text-[10px] lg:text-[10px] p-1'>{categories.name}</p>
    </div>
);
})}
</Slider>
<Slider {...settings} ref={sliderRef}>
{exploredata.slice(3,6).map((categories, index) => {
const isSelected = tourismType === categories.id;
return (
  <div key={index} onClick={()=>setTourismType(categories.id)} className={`cursor-pointer p-2 rounded-md transition duration-300 ease-in-out 
    ${isSelected ? 'bg-[#11A59B] text-white' : 'bg-white text-black'}
    hover:shadow-lg hover:scale-105`}>
      <img className='object-cover rounded-full w-auto mx-auto' src={baseUrl + categories.image_path} alt={categories.name} />
      <p className='text-center font-poppins font-semibold text-[10px] lg:text-[10px] p-1'>{categories.name}</p>
    </div>
);
})}
</Slider>
</div>


</div>
{/* End Tourism Type Section */}

<div>

<div className='flex items-center gap-3'>
<h1 onClick={toggleDropdown} className='cursor-pointer text-[#11A59B] font-poppins text-md font-semibold my-5'>Select Districts</h1>
<img onClick={toggleDropdown} src={!isOpen ? dwarrow:uparrow } className='w-4 cursor-pointer' />
</div>


{isOpen && (
    <ul className="bg-white border border-gray-300 rounded-md shadow-lg mt-2 p-3 w-64 z-10">
    {districts.map((district, index) => (
      <li
        key={index}
        className={`p-2 text-[#1E75BB] font-poppins border-[#11A59B] rounded-md border-[1px] m-2 hover:bg-slate-100 cursor-pointer ${
          selectedDistricts.includes(district)
            ? 'bg-slate-200 border-[#1E75BB]' // Mark selected items with different styles
            : ''
        }`}
        onClick={() => toggleSelection(district)}
      >
        {district.city}
      </li>
    ))}
    
  </ul>
   
      )}

<div className='h-12'></div>

<div className='grid grid-cols-2 justify-between items-center w-full'>
<button className=' bg-[#11A59B] text-[12px] py-2 px-4 text-white rounded-br-2xl font-poppins' onClick={HandleFilter}>Apply filter</button>
<p className='text-right font-poppins text-[12px] text-[#11A69B] font-bold tracking-wide cursor-pointer hover:text-red-500' onClick={HandleClear}>Clear Filter</p>
</div>

</div>



          </div>
          {/* End Grid-1 */}

        {/* Grid-2 */}
        {!mapview ?<div className='w-full'>
          {/* Image Card Section */}
          <div className="relative md:grid grid-cols-2 hidden sm:grid-cols-2 lg:grid-cols-3 gap-5 items-center justify-center mx-auto lg:py-0 p-2">
            {data.map((destination, index) => (
              <div key={index} className="relative mb-4 w-full rounded-lg hover:scale-105 transition duration-500 ease-in-out overflow-hidden shadow-lg">
                <img onClick={() => navigate('/destination-details',{ state: { destination } })}
                  src={baseUrl + destination.destination_image}
                  alt={destination.destination_name}
                  className="w-full lg:h-96 h-72 object-cover cursor-pointer"
                />
                <div className="absolute top-4 right-4 cursor-pointer bg-white p-1 rounded-full" onClick={() => handleLike(index,destination.id)}>
                  {likedDestinations.some(dest => dest.id === destination.id) ? (
                    <img src={greentick} className='w-8' />
                  ) : (
                    <div className='w-6 h-6 rounded-full'></div>
                  )}
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-transperant to-transparent p-4">
                <p className="text-lg font-bold text-white">{destination.destination_name}</p>
                <h2 className="text-sm text-white">{destination.destination_heading}</h2>
                </div>
              </div>
            ))}
           
          </div> 
          <div>


          <div className='flex gap-4 items-center bg-white px-6 py-1 border-[#11A59B] border-[1px] rounded-sm shadow-sm w-36 mx-auto'>
        <button className='text-[10px] font-poppins text-[#11A59B] font-semibold tracking-wide cursor-pointer text-center' onClick={decrement} disabled={countRef.current === 1}>Prev</button>
      <p className='text-[10px] font-poppins text-[#11A59B] font-semibold'>-{countRef.current}-</p>
      <button className='text-[10px] font-poppins text-[#11A59B] font-semibold tracking-wide cursor-pointer text-center'  onClick={increment} disabled={data === null}>Next</button>
    </div>
          </div>

          

          <div className='md:hidden block px-2 container mx-auto overflow-x-clip'>
         
            <Slider {...settings}>
              {data.map((destination, index) => (
                <div key={index} className="relative mb-4 w-full rounded-lg overflow-hidden shadow-lg">
                  <img
                    src={baseUrl + destination.destination_image}
                    alt={destination.destination_name}
                    className="w-full lg:h-96 h-72 object-cover"
                  />
                  
                  <div className="absolute top-4 right-4 cursor-pointer" onClick={() => handleLike(index, destination.destination_name,destination.id)}>
                    {likedDestinations.some(dest => dest.id === destination.id) ? (
                      <img src={greentick} className='w-8' />
                    ) : (
                      <div className='w-6 h-6 rounded-full'></div>
                    )}
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-transperant to-transparent p-4">
                    <h2 className="text-lg font-bold text-white">{destination.destination_heading}</h2>
                    <p className="text-sm text-white">{destination.destination_name}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* End Image Card Section */}
        </div> :<div>Map View</div>}





        {/* End Grid-2 */}
      </div>
      {/* End Body Main Section */}

      {/* Footer Section */}
      <Footer />
      {/* End Footer Section */}

      {/* Background Overlay */}
      {sideNavOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setSideNavOpen(false)}
        ></div>
      )}

      {/* Side Nav for Liked Destinations */}
      <div className={`fixed top-0 right-0 h-full rounded-l-3xl bg-white shadow-lg p-6 transform ${sideNavOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 z-40 w-[80%] md:w-[50%] lg:w-[40%] xl:w-[30%] overflow-y-scroll`}>
        <button className="text-gray-500 text-xl absolute top-4 right-4" onClick={() => setSideNavOpen(false)}>
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4 font-poppins text-[#11A59B]">Selected Destinations</h2>
        {favoritedata ? (
          <div className="space-y-4 p-3">
            {favoritedata.map((favdestination, index) => (
              
              
              <div key={index} className="items-center border-[#11A59B] border-[2px] rounded-lg  p-3">
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-top'>
                <div>
                <img
                  src={baseUrl + favdestination.destination_image}
                  alt={favdestination.destination_name}
                  className="w-full object-cover rounded-lg mr-4"
                />
                </div>
                <div>
                <h3 className="font-bold font-poppins text-[#006464] md:text-xl text-lg">{favdestination.destination_name}</h3>
                <p className='text-[12px] font-poppins text-justify'>{favdestination.destination_heading}</p>
               
                </div>
                
                </div>      
               
                <div className='grid grid-cols-2 w-full items-center font-poppins justify-between'
                //  onClick={() => handleRemove(data.indexOf(destination))}
                 >
  <div className='w-full'></div>
  <div className='flex justify-end gap-4 items-center w-full cursor-pointer' onClick={() => handleRemove(index,favdestination.id)}>
    <button>REMOVE</button>
    <img src={vector} alt='minus' />
  </div>
</div>
 
              </div>
            ))}
          </div>
        ) : (
          <p className='text-[#006464] font-poppins'>No liked destinations.</p>
        )}
        
          
       {likedDestinations.length > 0 ?<div className="flex justify-center sm:justify-between gap-2 mt-4 mx-auto">
          <button
           
            className="bg-white text-[#1E75BB] p-2 px-10 sm:px-12 md:px-14 py-3 font-poppins font-semibold rounded-full border-[1px] border-[#1E75BB]"
          onClick={()=>setSideNavOpen(false)}>
            BACK
          </button>

          <button onClick={()=>navigate('/confirm-plan')}
            className="bg-[#11A59B] text-white p-2 px-10 sm:px-12 md:px-14 py-4 font-poppins font-semibold rounded-full"
          >
            CONFIRM
          </button>
        </div>:''}



      </div>
    </div>
  );
}

export default ExploreDestination;
