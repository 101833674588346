// src/components/Popup.js
import React, { useEffect, useState } from "react";
import MobileInput from "./MobileInput";
import { FaTimes } from "react-icons/fa";
import { CircleXIcon } from "lucide-react";

const Popup = ({ onCloseClick }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up the effect on component unmount
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isVisible]);

  const handleOtpSuccess = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="fixed inset-0 flex items-center justify-center z-50 px-[2px]">
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm" />
        <div className="bg-transparent shadow-lg relative z-10 w-full sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] rounded-2xl overflow-hidden">
          <button
            onClick={() => {
              onCloseClick();
            }}
            className="group absolute right-4 top-4"
          >
            <CircleXIcon className="text-gray-300 group-hover:text-gray-400" />
          </button>
          {/* Content here */}
          <MobileInput onOtpSuccess={handleOtpSuccess} />
        </div>
      </div>
    )
  );
};

export default Popup;
